import React, { useState, useRef, useEffect } from 'react';
import { graphql } from "gatsby";
import Layout from "../components/layout"
import { color } from "../components/defaults/styles/theme"
import { wrapper, ExtendButton, TitleHeader, IconButton, IconButtonOff } from "../components/defaults/styles/elements"
import { css } from "@emotion/core"
import PressFooter from "../components/pressfooter"
import ReactPlayer from 'react-player/youtube'
import Link from '../components/link';
import mq from '../components/defaults/styles/mediaquerys';
import Share from '../components/defaults/assets/svg/share.svg';
import Video from '../components/defaults/assets/svg/video.svg';
import { HackerNews, Reddit, WhatsApp, Telegram, Twitter, Linkedin } from '../components/sharing';
import { People } from '../components/main/agenda-el';
import moment from "moment"
import { ChevronLeft, ChevronRight } from '../components/defaults/assets/svg/pagination';
import PartnerLogo from '../components/main/logo-partner';
import StartupLogo from '../components/main/logo-startup';



const Player = ({url, timestamp}) => {
  let playerUrl = url
  if (timestamp) {
    const shortUrl = /^(https:\/\/youtu.be\/)/g
    const fullUrl = /^(https:\/\/youtube.com\/)/g
    if (playerUrl.match(shortUrl)) {
      playerUrl = playerUrl.concat("?t=" + timestamp + "s")
    } else if (playerUrl.match(fullUrl)) {
      playerUrl = playerUrl.concat("&t=" + timestamp + "s") 
    }

  }

  return (
    <div
      css={css`
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden;
      `}
    >
      <ReactPlayer
        url={playerUrl}
        width="100%"
        height="100%"
        controls
        light
        css={css`
          position: absolute;
          top: 0;
          left: 0;
      `}
      />
    </div>
  )
}

const InfoItem = ({ title, content }) => {
  const type = typeof content
  
  return (
    <>
      <li
        css={css`
          display: flex;
          border-bottom: 1px solid white;
          justify-content: space-between;
          margin: 0;
          line-height: 1em;
          padding: 1em 0.5em;
        `}
      >
        <div>
          <p
            css={css`
              font-weight: 700;
            `}
          >
            {title}
          </p>
        </div>
        {type === "string" ? (
          <div
            css={css`
              p {
                margin-bottom: 0;
              }
            `}
          >
            {content}
          </div>
        ) : (
          <div
            css={css`
              display: flex;
              justify-content: flex-end;
              flex-wrap: wrap;
              align-items: flex-start;
            `}
          >
            {content.sort().map((el, index) => (
              <Tag key={index} content={el} />
            ))}
          </div>
        )}
      </li>
    </>
  )
}

const Tag = ({content}) => {
  return (
    <p css={css`
      background: white;
      display: inline;
      line-height: 1em;
      white-space: nowrap;
      padding: .5em .75em;
      border-radius: 5px;
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: .07em;
      font-size: .75em;
      color: ${color.main_dark};
      margin: .25em;
      &:first-of-type {
        margin-left: 0;
      }
      &:last-of-type {
        margin-right: 0;
      }
    `}>{content}</p>
  )
}

const Sidebar = ({ className, location, data }) => {
  const [shareState, setShareState] = useState("")
  const [shareHeight, setShareHeight] = useState("0px");
  const refShare = useRef(null)

  const handleShareClick = () => {
    setShareState(shareState === "" ? "active" : "")
    if (shareState === "active") {
      setShareHeight( "0px" )
    } else {
      setShareHeight( `${refShare.current.scrollHeight}px` );
    }
  }

  
  return (
    <div
      className={className}
      css={css`
        background: ${color.accent_light};
        border-radius: 5px;
        > * {
          :not(first-of-type) {
            margin-right: 0.5em;
          }
        }
      `}
    >
      <button
        onClick={handleShareClick}
        css={css`
          display: flex;
          align-items: center;
          width: 100%;
          background: none;
          border: none;
          padding: 1em;
          outline: none;
          border-bottom: 1px solid ${color.main_dark};
          border-radius: 5px;
          &:hover {
            cursor: pointer;
          }
          p {
            margin-bottom: 0;
            line-height: 1em;
            letter-spacing: 0.02em;
          }
          svg {
            width: 1.5em;
            height: auto;
            margin-right: 1em;
          }
        `}
      >
        <Share />
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            flex: 1 0 auto;
          `}
        >
          <p
            css={css`
              font-size: 0.75em;
            `}
          >
            Share this video
          </p>
          <p
            css={[
              css`
                opacity: 0.3;
                text-transform: uppercase;
                font-weight: 700;
                font-size: 0.65em;
              `,
            ]}
          >
            {shareState === "active" ? "close" : "open"}
          </p>
        </div>
      </button>
      <div
        ref={refShare}
        css={css`
          height: ${shareHeight};
          overflow: hidden;
          transition: height ease-in-out 0.3s;
        `}
      >
        <div
          css={css`
            padding: 1em;
            display: flex;
            flex-direction: column;
            > * {
              :not(:last-child) {
                margin-bottom: 0.5em;
              }
            }
          `}
        >
          <Linkedin
            link={location.href}
            message={`${data.rearview_title} by @startupautobahn #EXPO8 #startupautobahn`}
          />
          <Twitter
            link={location.href}
            message={`${data.rearview_title} by @startupautobahn #EXPO8 #startupautobahn`}
          />
          <HackerNews
            link={location.href}
            message={`${data.rearview_title} by @startupautobahn #EXPO8 #startupautobahn`}
          />
          <WhatsApp
            link={location.href}
            message={`${data.rearview_title} by @startupautobahn #EXPO8 #startupautobahn`}
          />
          <Reddit
            link={location.href}
            message={`${data.rearview_title} by @startupautobahn #EXPO8 #startupautobahn`}
          />
          <Telegram
            link={location.href}
            message={`${data.rearview_title} by @startupautobahn #EXPO8 #startupautobahn`}
          />
        </div>
      </div>
    </div>
  )
}

const Description = ({data}) => {
  const [activeReadmore, setActiveReadmore] = useState("")
  const [descriptionState, setDescriptionState] = useState("")
  const [descriptionHeight, setDescriptionHeight] = useState("unset")
  const refDescription = useRef(null)

  const toggleDescription = () => {
    setDescriptionState(descriptionState === "" ? "active" : "")
    
    if (descriptionState === "active") {
      setDescriptionHeight("13em")
    } else {
      setDescriptionHeight(`${refDescription.current.scrollHeight + 50}px`)
    }
  }

  const checkHeight = refDescription => {
    if (refDescription && refDescription.current !== null && refDescription.current.scrollHeight > 208) {
      setActiveReadmore(true);
      setDescriptionHeight("13em");
    }
  }

  useEffect(() => {
    checkHeight(refDescription)
  }, [])

  return (
    <>
      <h3
        id="description"
        css={[
          css`
            color: white;
            margin-bottom: 1em;
          `,
        ]}
      >
        Description
        {data.airtable.data.rearview_people &&
        data.airtable.data.rearview_people.length > 0 ? (
          <span css={css`
            opacity: ${descriptionState ? "0" : "1"};
            transition: opacity ease-in-out .4s;
          `}> & People</span>
        ) : (
          ``
        )}
      </h3>
      {(data.airtable.data.rearview_description &&
        data.airtable.data.rearview_description !== undefined) ||
      (data.airtable.data.rearview_people &&
        data.airtable.data.rearview_people.length > 0) ? (
        <div
          ref={refDescription}
          css={css`
            height: ${descriptionHeight};
            overflow: hidden;
            position: relative;
            transition: height ease-in-out 0.4s;
          `}
        >
          {data.airtable.data.rearview_description &&
          data.airtable.data.rearview_description !== undefined ? (
            <div
            css={css`
              color: white;
              max-width: 45em;
              p, h1, h2, h3 {
                margin-bottom: 0.5em;
                font-size: 0.9em;
              }
              h1, h2, h3 {
                font-weight: 700;
                line-height: 1.6em;
              }
              a {
                color: white;
              }
            `}
            dangerouslySetInnerHTML={{
              __html:
                data.airtable.data.rearview_description.childMarkdownRemark
                  .html,
            }}
          />) : ``}
          <Actors data={data} />
          <ExtendButton
            onClick={toggleDescription}
            activeState={descriptionState}
            css={css`
              display: ${activeReadmore ? "flex" : "none"};
            `}
          />
        </div>
      ) : (
        <div css={css`
          ref={refDescription}
        `}>
          <p>no information has been provided</p>
        </div>
      )}
    </>
  )
}

const Actors = ({data}) => {
  if (
    data.airtable.data.rearview_people &&
    data.airtable.data.rearview_people.length > 0
  ) {
    return (
      <div css={css`
        margin: 3em 0;
      `}>
        <h3 css={css`
          color: white;
        `}>People
          <span css={css`
            font-size: .75rem;
            opacity: .5;
            padding-left: 1em;
            font-weight: 400;
            letter-spacing: .03em;
          `}>click for more information</span>
        </h3>
        <div css={css`
          max-width: 55rem;
        `}>
          <People speakers={data.airtable.data.rearview_people} />
        </div>
      </div>
    )
  } else {
    return null
  }
}

const RearviewNavigator = ({className, prev, next, index, length}) => {

  const btnPlacement = css`
    display: flex;
    align-items: center;
    color: ${color.main_dark};
    padding: 0.375rem 0.7rem;
    svg {
      stroke: ${color.main_dark};
    }
    &:before {
      border: 1px solid ${color.main_dark};
      background: none;
    }
  `

  return (
    <div
      className={className}
      css={css`
        background: ${color.accent_light};
        position: relative;
        z-index: 1;
        border-radius: 5px;
        padding: 1.5em 1em;
        display: flex;
        justify-content: space-between;
        align-items: center;
      `}
    >
      {index > 1 ? (
        <IconButton
          name="Prev"
          url={`/rearview/${prev.replace(/^(rec)/g, "")}`}
          icon={<ChevronLeft />}
          css={[btnPlacement, css``]}
        />
      ) : (
        <IconButtonOff
          name="Prev"
          icon={<ChevronLeft />}
          css={[
            btnPlacement,
            css`
              opacity: 0.2;
            `,
          ]}
        />
      )}

      <div
        css={css`
          line-height: 1em;
        `}
      >
        <p
          css={css`
            margin: 0;
            font-weight: 600;
            font-size: 0.75em;
            opacity: 0.5;
            color: ${color.main_dark};
          `}
        >{`Video ${index} of ${length}`}</p>
      </div>

      {index < length ? (
        <IconButton
          name="Next"
          url={`/rearview/${next.replace(/^(rec)/g, "")}`}
          icon={<ChevronRight />}
          css={[
            btnPlacement,
            css`
              flex-direction: row-reverse;
              svg {
                margin-right: 0;
                margin-left: 0.5em;
              }
            `,
          ]}
        />
      ) : (
        <IconButtonOff
          name="Next"
          icon={<ChevronRight />}
          css={[
            btnPlacement,
            css`
              flex-direction: row-reverse;
              opacity: 0.2;
              svg {
                margin-right: 0;
                margin-left: 0.5em;
              }
            `,
          ]}
        />
      )}
    </div>
  )
}

const PilotPartner = ({id, recordId, name, ...props}) => {
  let path = ""
  if (props.partner) {
    path = "/partners/" + decodeURIComponent(name
      .replace(/\./g, "")
      .replace(/&/g, "")
      .replace(/\s/g, "")
      .replace(/-/g, "")
      .replace(/é/g, "e")
    )
  } else if (props.startup) {
    path = "/all-access/startups/" + id
  }

  return (
    <Link
      to={path}
      css={css`
        background: ${props.placeholder ? "none" : "white"};
        border-radius: 5px;
        padding: ${props.placeholder ? "0" : "2em"};
        height: ${props.placeholder ? "none" : "6em"};
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 1em;
        flex: 0 0 calc(100% / 2 - 0.5em);
        ${mq[0]} {
          flex: 0 0 calc(100% / 3 - 0.75em);
          margin-bottom: 1.25em;
        }
        ${mq[1]} {
          flex: 0 0 calc(100% / 4 - 0.75em);
        }
        ${mq[2]} {
          flex: 0 0 calc(100% / 3 - 0.75em);
        }
        ${mq[3]} {
          flex: 0 0 calc(100% / 4 - 0.75em);
        }
        svg {
          width: 100%;
          height: 100%;
        }
      `}
    >
      {props.partner ? (
        <PartnerLogo id={recordId} />
      ) : props.startup ? (
        <StartupLogo id={recordId} />
      ) : (
        ""
      )}
    </Link>
  )
}


const RearviewPage = ({data, location, pageContext}) => {
  const seo = {
    frontmatter: {
      title: data.airtable.data.rearview_title,
      slug: `rearview/${data.airtable.recordId}`
    },
  }

  const {prev, next, index, length} = pageContext;
  let date = "-"
  let time = "-"
 if ( data.airtable.data.rearview_release ) {
    date = moment(data.airtable.data.rearview_release).format(
      "MMMM Do, YYYY (ddd)"
    )
    time = moment(data.airtable.data.rearview_release).local().format("h:mm A")
    time = time + " CEST"
 } else if (
    data.airtable.data.rearview_agenda &&
    data.airtable.data.rearview_agenda !== undefined &&
    data.airtable.data.rearview_agenda !== null
  ) {
    const rawdate = data.airtable.data.rearview_agenda[0].data.agenda_start_time
    date = moment(rawdate).format("MMMM Do, YYYY (ddd)")
    time = moment(rawdate).local().format("h:mm A")
    time = time + " CEST"
  }

  
  
  return (
    <Layout dark seo={seo}>
      <div
        css={[
          css`
            background: ${color.main_dark};
          `,
        ]}
      >
        <TitleHeader
          transparent
          dark
          title={data.airtable.data.rearview_title}
          subtitle={`${
            data.airtable.data.rearview_description
              ? data.airtable.data.rearview_description.childMarkdownRemark
                  .excerpt + '<a class="moreEl" href="#description">MORE</a>'
              : ""
          }`}
          icon={<Video />}
          css={[
            css`
              h2 {
                max-width: 50rem;
              }
              .moreEl {
                font-weight: 700;
                text-decoration: none;
              }
            `,
          ]}
        />

        <section
          css={[
            wrapper,
            css`
              padding-bottom: 8em;
            `,
          ]}
        >
          <RearviewNavigator
            prev={prev}
            next={next}
            index={index}
            length={length}
            css={css`
              margin: ${data.airtable.data.rearview_description ? "2em" : "4em"}
                0 2em;
            `}
          />

          <div
            css={css`
              display: flex;
              align-items: flex-start;
            `}
          >
            <div
              css={css`
                flex: 1 0 auto;
              `}
            >
              <Player url={data.airtable.data.rearview_link} timestamp={data.airtable.data.rearview_timestamp} />
            </div>
            <Sidebar
              data={data.airtable.data}
              location={location}
              css={css`
                display: none;
                margin-left: 1em;
                flex: 0 1 19rem;
                ${mq[2]} {
                  display: block;
                }
              `}
            />
          </div>

          <div
            css={css`
              margin: 2em 0;
            `}
          ></div>

          <div
            css={css`
              color: white;
              display: flex;
              margin-top: 5em;
              flex-direction: column;
              ${mq[2]} {
                flex-direction: row;
              }
              h2 {
                font-size: 1.25rem;
              }
            `}
          >
            <div
              css={css`
                position: relative;
                z-index: 1;
                ${mq[2]} {
                  flex: 1 0 20rem;
                  padding-right: 5em;
                }
              `}
            >
              <Description data={data} />

              {data.airtable.data.rearview_tags.includes("pilot videos") &&
              (data.airtable.data.rearview_partners ||
                data.airtable.data.rearview_startups) ? (
                <div
                  css={css`
                    margin-top: 5em;
                  `}
                >
                  <h2>Pilot Partners</h2>
                  <div
                    css={css`
                      display: flex;
                      flex-wrap: wrap;
                      justify-content: space-between;
                    `}
                  >
                    {data.airtable.data.rearview_partners
                      .sort((a, b) => (a.data.name > b.data.name ? 1 : -1))
                      .map(partner => (
                        <PilotPartner
                          partner
                          recordId={partner.recordId}
                          name={partner.data.name}
                          id={partner.id}
                        />
                      ))}
                    {data.airtable.data.rearview_startups
                      .sort((a, b) => (a.data.startup > b.data.startup ? 1 : -1))
                      .map(startup => (
                        <PilotPartner
                          startup
                          recordId={startup.recordId}
                          name={startup.data.startup}
                          id={startup.id}
                        />
                      ))}
                    <PilotPartner placeholder />
                    <PilotPartner placeholder />
                    <PilotPartner placeholder />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>

            <Sidebar
              data={data.airtable.data}
              location={location}
              css={css`
                margin: 1em 0 3em;
                ${mq[2]} {
                  display: none;
                }
              `}
            />

            <div
              css={css`
                /* max-width: 20rem; */
                /* margin-top: 4em; */
                ${mq[1]} {
                  /* flex: 1 0 10rem; */
                  flex: 0 1 19rem;
                  margin-top: 0;
                }
              `}
            >
              <h3
                css={css`
                  margin-bottom: 1em;
                `}
              >
                Information
              </h3>
              <ul
                css={css`
                  border-top: 1px solid white;
                  margin: 0;
                  width: 100%;
                  font-size: 0.75em;
                `}
              >
                <InfoItem title="Release Date" content={date} />
                <InfoItem title="Time of Publishing" content={`${time}`} />
                <InfoItem title="Event" content="STARTUP AUTOBAHN EXPO 8" />
                <InfoItem title="Provider" content="Youtube" />
                <InfoItem
                  title="Tags"
                  content={data.airtable.data.rearview_tags}
                />

                <li
                  css={css`
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 2em 0.5em;
                  `}
                >
                  <div>
                    <Link
                      to={data.airtable.data.rearview_link}
                      css={css`
                        border: 1px solid white;
                        border-radius: 5px;
                        padding: 0.75em 1em 0.65em;
                        color: white;
                        text-decoration: none;
                        display: block;
                        line-height: 1em;
                      `}
                    >
                      Open Stream Link
                    </Link>
                  </div>
                  <div>
                    <Link
                      to="mailto:marketing-ger@pnptc.com"
                      css={css`
                        color: white;
                        font-size: 0.85em;
                      `}
                    >
                      Report a problem
                    </Link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
      <PressFooter theme={color.main_dark} />
    </Layout>
  )
}

export default RearviewPage;

export const query = graphql`
         query RearviewEl($id: String!) {
           airtable(id: { eq: $id }, table: { eq: "rearview" }) {
             id
             recordId
             data {
               rearview_link
               rearview_tags
               rearview_title
               rearview_release
               rearview_people {
                 id
                 data {
                   name
                   profession
                   partner_company {
                     data {
                       name
                     }
                   }
                   custom_company
                   startup_company {
                     data {
                       startup
                     }
                   }
                 }
               }
               rearview_timestamp
               rearview_description {
                 childMarkdownRemark {
                   excerpt(format: HTML, pruneLength: 240)
                   html
                 }
               }
               rearview_agenda {
                 data {
                   agenda_start_time
                 }
               }
               rearview_partners {
                 id
                 recordId
                 data {
                   name
                 }
               }
               rearview_startups {
                 id
                 recordId
                 data {
                   startup
                 }
               }
             }
           }
         }
       `
