import React from "react";

import {
  Adac,
  Agc,
  Arena2036,
  Basf,
  Bleistahl,
  Bosch,
  Bp,
  Covea,
  Daimler,
  Dpdhl,
  Dxc,
  Eberspaecher,
  Faurecia,
  Hyundai,
  Jardines,
  Linde,
  Motherson,
  Murata,
  Pnp,
  Porsche,
  Posteitaliane,
  Rrps,
  Sekisui,
  Tsystems,
  Tuev,
  Unistuttgart,
  Wabco,
  Webasto,
  Wieland,
  Yanfeng,
  Zfwabco,
} from "../defaults/assets/logos/partnerlogos/index"

 const PartnerIcons = {
   recnc3kN5B46c5vuU: Adac,
   reczuYGp4lRVlKU7f: Agc,
   recpkliMcxIAYbCb4: Arena2036,
   recmNw8uqRSGFZPoV: Basf,
   recv4XgFwnm4TBilr: Bleistahl,
   recMyUJ5DDuEZWvsp: Bosch,
   recRZRsV8tS5cC1PO: Bp,
   rec9IVjLhearEySWp: Covea,
   recMZO6WOEd9AUz5V: Daimler,
   recxab7uBfGDl0ER3: Dpdhl,
   recknXqi5Opmf1mzL: Dxc,
   recfc05bPCyg7uCDX: Eberspaecher,
   rechPqzRBLCgoMDrJ: Faurecia,
   recxubEQSiACFjVs9: Hyundai,
   recW3d18hhJYpvroh: Jardines,
   recupeFtKglPqA0uu: Linde,
   recsQ6aTmDVuhr8IT: Motherson,
   rectwB3wBSrWhgsk0: Murata,
   recnOPFgTwWxTAIC5: Pnp,
   recEXSnc20uRqXMm9: Porsche,
   recfMJ3lIw7pDuRJ9: Posteitaliane,
   recfumBINyQLFYOxi: Rrps,
   recByWKImuNqVASq1: Sekisui,
   recaRZMAGkJbfIffO: Tsystems,
   rec1ah17vLKQU2RH9: Tuev,
   recsjHnB69egVYXw3: Unistuttgart,
   recx6kj5HWEYP7SBx: Wabco,
   recRdcKnCaOWpjJO1: Webasto,
   recE0yjT88CHpwVHA: Wieland,
   reczhqCsY42xax7WQ: Yanfeng,
   recRbrz7q1GYviSXa: Zfwabco,
 }

 const PartnerLogo = ({id, className}) => {
     let Icon = undefined;
     
     if (PartnerIcons[id] !== undefined) {
         Icon = PartnerIcons[id]
     }
     return(
        <>
            { Icon ? (
                <Icon className={className} />
            ) : ''}
        </>
     )
 }

 export default PartnerLogo;