import React from 'react';
import Link from './link';
import { color } from '../components/defaults/styles/theme';
import { css } from '@emotion/core';

import { Linkedin as LinkedinIcon, Twitter as TwitterIcon, HackerNews as HackerNewsIcon, WhatsApp as WhatsAppIcon, Reddit as RedditIcon, Telegram as TelegramIcon, Mail as MailIcon } from './defaults/assets/svg/social';

const CreateButton = ({name, href, icon}) => {
  return (
    <Link 
      href={href}
      css={css`
        background: ${color.main_dark};
        color: white;
        text-decoration: none;
        display: inline-flex;
        font-size: .75em;
        letter-spacing: .03em;
        padding: 1em 1em;
        line-height: 1em;
        border-radius: 3px;
        align-items: center;
        svg {
          height: 1em;
          width: auto;
          fill: white;
          margin-right: .5em;
        }
      `}
    >
    {icon}
    {name}
    </Link>
  )
}

const links = {
  twitter: (link = "", message = "") =>
    `https://twitter.com/intent/tweet/?text=${encodeURIComponent(
      message
    )}&url=${encodeURIComponent(link)}`,
  reddit: (link = "", message = "") =>
    `https://reddit.com/submit/?url=${encodeURIComponent(
      link
    )}&title=${encodeURIComponent(message)}`,
  whatsapp: (link = "", message = "") =>
    `https://api.whatsapp.com/send?text=${encodeURIComponent(
      message
    )}%20${encodeURIComponent(link)}`,
  telegram: (link = "", message = "") =>
    `https://telegram.me/share/url?text=${encodeURIComponent(
      message
    )}&url=${encodeURIComponent(link)}`,
  hacker: (link = "", message = "") =>
    `https://news.ycombinator.com/submitlink?u=${encodeURIComponent(
      link
    )}&t=${encodeURIComponent(message)}`,
  mail: (link = "", subject, body) =>
    `mailto:?subject=${encodeURIComponent(
      subject || ""
    )}&body=${encodeURIComponent((body && `${body}\n\n${link}`) || link)}`,
  linkedin: (link = "", message = "") =>
    `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
      link
    )}`,
}


export const HackerNews = ({link, message}) => {
  return (
  <CreateButton
    name="HackerNews"
    href={links.hacker(link, message)}
    icon={<HackerNewsIcon />}
  />
  )
}

export const Reddit = ({link, message}) => {
  return (
  <CreateButton
    name="Reddit"
    href={links.reddit(link, message)}
    icon={<RedditIcon />}
  />
  )
}

export const WhatsApp = ({link, message}) => {
  return (
  <CreateButton
    name="WhatsApp"
    href={links.whatsapp(link, message)}
    icon={<WhatsAppIcon />}
  />
  )
}

export const Telegram = ({link, message}) => {
  return (
  <CreateButton
    name="Telegram"
    href={links.telegram(link, message)}
    icon={<TelegramIcon />}
  />
  )
}

export const Twitter = ({link, message}) => {
  return (
  <CreateButton
    name="Twitter"
    href={links.twitter(link, message)}
    icon={<TwitterIcon />}
  />
  )
}

export const Mail = ({link, subject, body}) => {
  return (
  <CreateButton
    name="HackerNews"
    href={links.mail(link, subject, body)}
    icon={<MailIcon />}
  />
  )
}

export const Linkedin = ({link, message}) => {
  return (
  <CreateButton
    name="Linkedin"
    href={links.linkedin(link, message)}
    icon={<LinkedinIcon />}
  />
  )
}

